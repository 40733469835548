import styled from 'styled-components'


export const Accordions = styled.div`  
  padding:0;
`
export const Accordionsitems = styled.div`
  position:relative;
  background: #f8fafe;  
  + .accordions-items{
    margin-top:40px;
  }

  h2 {
    margin: 10px 0 !important;
  }
  
  &.active{
    > .accordions-title{
      background-color: #0a5a90;
      border-color:#0a5a90;
      color:#fff;
      span{
        transform:rotate(225deg);
        &:before, &:after{
          background-color:#fff;
        }
      }
    }
    
    > .accordions-content{
      max-height: 10000px;
      overflow: inherit;
      transition: max-height 0.2s ease-out;

      .state {
        max-height: 400px;
        overflow-y: scroll;
      }
    }
  }
  .product-list {
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
  }

  &.sub-accordions{
    + .accordions-items{
      margin-top:5px;      
    }
    > .accordions-title{
      border:none;
      font-weight:400;
      font-size: 18px;
      line-height: 22px;
      @media(min-width:768px){
        font-size: 20px;
        line-height: 24px;
      }
      @media(min-width:1600px){
        font-size: 22px;
        line-height: 28px;
      }
      > a{
        color:#444;
        &:hover, &:focus{
          color:#000;
        }
      }
    }
    &.active{
      > .accordions-title{
        background-color:#DDE4F0;
        color:#000;
        span{
          &:before, &:after{
            background-color:#000;
          }
        }
      }
    }
  }
`
export const AccordionsContent = styled.div`
  max-height:0;
  overflow:hidden;
  transition: max-height .35s ease;
  position: relative;
  letter-spacing: 0.18px;
  color:#333;

  font-size:16px;
  line-height:30px; 
  @media(min-width:1200px){
    font-size:18px;
    line-height:32px; 
  }
  @media(min-width:1600px){
    font-size: 20px;
    line-height: 34px;
  }
`
export const AccordionsContentinner = styled.div`
  padding:30px;
  h2{
    margin:30px 0 10px;
    font-weight:700;
  }
`
export const AccordionsTitle = styled.div`
  background-color:#fff;
  border:1px solid #ccc;
  box-shadow:0px 10px 15px 0px rgba(194,196,200,0.2);
  cursor: pointer;
  display:flex;
  align-items:center;
  justify-content:space-between;
  position:relative;
  z-index:1;
  color: #131413;
  font-weight: 700;

  font-size: 20px;
  line-height: 24px;
  padding:8px 30px;
  @media(min-width:768px){
    font-size: 22px;
    line-height: 28px;
  }
  @media(min-width:1600px){
    font-size: 24px;
    line-height: 38px;
    padding:12px 30px;
  }

  span{
    width:30px; 
    min-width: 30px;
    height:30px; 
    position:relative;
    display:inline-block;
    transition: all .35s ease;
    margin-left:30px;
    &:before,
    &:after{
      display:block;
      content:'';
      position:absolute;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      background:#000;
    }
    &:before{
      width:2px;
      height:50%;
    }
    &:after{
      width:50%;
      height:2px;
    }
  }
`