import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { Section, SectionPageTitle } from "../components/Section"
import SitemapAccordion from "../components/SitemapAccordion"

const SiteMapWrap = styled.div`
    background-color: #fff;
    padding: 30px;
    position: relative;
    text-align: center;
    @media(min-width:768px){    
        padding:70px; 
        text-align: left;
    }
    &:before {
        content: "";
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        border: 1px solid #dde4f0;
       @media(min-width:768px){
            border: 4px solid #DDE4F0;
            top: 20px;
            right: 20px;
            bottom: 20px;
            left: 20px;
        }
    }

`

const SitemapPage = ({ location, data }) => (
  <Layout location={location}>
    <Seo title="Sitemap" description="Sitemap" />
    <Section
      pt="215px"
      pb="90px"
      xpt="54px"
      xpb="60px"
      bgColor="#F2F4F9"
      className="circle-left"
    >
      <div className="container">        
        <SectionPageTitle>Sitemap</SectionPageTitle>
        <SiteMapWrap>
          <SitemapAccordion
            products={data.allContentfulProduct.edges}
            states={data.allContentfulState.edges}
          />
        </SiteMapWrap>
      </div>
    </Section>
  </Layout>
)

export default SitemapPage

export const pageQuery = graphql`
  query SiteMapPageQuery {
    allContentfulProduct {
      edges {
        node {
          productName
          url
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          abbreviation
        }
      }
    }
  }
`
